$padding-lg: 24px;
$primary-color-1: #007bff;
$primary-color-2: #415a77;
$primary-color-3: #778da9;
$primary-color-5: #54595f;
$primary-color-6: #e0e1dd;

$primary-red: rgb(255, 77, 79);
$primary-green: #00b96b;

$primary-text: #1b263b;

$secondary-background-color: #f5f5f5;
