@import "../../../../../../styles/variables.scss";

.title-divider-container {
  width: 100%;
  padding: 2px 4px;
  background-color: $primary-color-2;
  display: flex;
  justify-content: center;

  &.title-divider-container--alt {
    background-color: $primary-color-6;

    span {
      color: $primary-text;
    }
  }

  span {
    color: $primary-color-6;
    font-size: 0.9em;
    margin: auto;
  }
}
