@import "../../../../../../styles/variables.scss";

.rf-standard-node {
  width: 100%;
  background: $primary-color-6;
  border-radius: 5px;

  .standard-node {
    width: 200px;
    .standard-node--header {
      padding: 4px 4px;
      padding-right: 8px;

      .agent-avatar {
        background-color: $primary-text;
      }
    }
    .standard-node--body {
      padding: 8px;
    }
    .standard-node--footer {
      padding: 8px;
    }

    .divider {
      margin: 0px;
      border-color: $primary-color-6;
    }
  }

  &.selected-node {
    border: 3px solid $primary-red;
  }

  &.starting-node {
    background: $primary-green;
  }
}
