.sider-container {

  .menu-avatar {
    min-height: 25px;
    min-width: 25px;
    height: 25px;
    margin-top: 5px;

    .ant-avatar-string {
      font-size: 12px;
    }
  }

  .ant-menu-title-content {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-menu-item {
    padding-top: 2px;
  }

  .logo {
    height: 25px;
    width: 25px;
    border-radius: 4px;
  }
}