.workflow-container {
  height: 100%;
  display: flex;
  flex-direction: column;

  .workflow-content {
    display: flex;
    height: 100%;
    width: 100%;
    margin: 0 auto;

    .react-flow-container,
    .chat-container {
      transition: width 0.1 ease;
    }

    .react-flow-container {
      height: 100%;
      flex: 1;
      position: relative;
    }

    .chat-container {
      // background: red;
    }
  }

  .react-flow__panel.react-flow__attribution.bottom.right {
    display: none;
    position: fixed;
    right: -100px;
  }
}
