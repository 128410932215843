@import "../../styles/variables.scss";

.upgrade-icon {
  // background: linear-gradient(to right, #ff0000, #00ff00);
  svg {
    // background-color: transparent;
  }
}

.main-sidenav {
  .menu-avatar {
    background-color: $primary-text;
  }

  .ant-menu-item {
    cursor: pointer;
  }
}
